/* global axios */

import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  bulkInvite({ emails }) {
    return axios.post(`${this.url}/bulk_create`, {
      emails,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  emblueCreateAgent(data) {
    // Get and decode eb_info cookie
    const ebInfo = document.cookie
      .split('; ')
      .find(row => row.startsWith('eb_info='))
      ?.split('=')[1];

    if (!ebInfo) {
      return Promise.reject(new Error('Emblue info cookie not found'));
    }

    const decodedInfo = decodeURIComponent(ebInfo);
    const emblueData = JSON.parse(decodedInfo);

    if (!emblueData?.apiKey) {
      return Promise.reject(new Error('Emblue API key not found'));
    }

    const emblueHeaders = {
      Authorization: `Bearer ${emblueData.apiKey}`,
    };

    return axios.post(
      `${window.emblueConfig.apiUrl}/v2.3i/inbox/agent_user`,
      { email: data.email },
      { headers: emblueHeaders }
    );
  }
}

export default new Agents();
